/**
 * 日期范围工具类
 */
 export function startTime(time) {
    const nowTimeDate = new Date(time)
    return nowTimeDate.setHours(0, 0, 0, 0)
  }
  
  export function endTime(time) {
    const nowTimeDate = new Date(time)
    return nowTimeDate.setHours(23, 59, 59, 999)
  }
  
  /***
   * 当前时间
   */
  export function getCurrentDate() {
    return new Date();
  }
  
  /***
   * 今天的开始时间
   */
  export function getBeginToday() {
    return new Date(new Date(new Date().toLocaleDateString()).getTime());
  }
  
  /***
   * 昨天开始时间
   */
  export function getBeginYesterday() {
    return startTime(getBeginToday() - 24 * 60 * 60 * 1000);
  }
  
  
  /***
   * 昨天结束时间时间
   */
  export function getEndYesterday() {
    return endTime(getBeginToday() - 24 * 60 * 60 * 1000);
  }
  /***
   * 本周的第一天时间
   */
  export function getBeginWeek() {
    var currentDate = getCurrentDate();
    var week = currentDate.getDay();
  
    //一天的毫秒数
    var millisecond = 1000 * 60 * 60 * 24;
    //减去的天数
    var minusDay = week != 0 ? week - 1 : 6;
    //本周 周一
    var monday = new Date(currentDate.getTime() - (minusDay * millisecond));
    return startTime(monday);
  }
  
  /***
   * 本周的最后一天时间
   */
  export function getEndWeek() {
    var currentDate = getCurrentDate();
    var week = currentDate.getDay();
    //一天的毫秒数
    var millisecond = 1000 * 60 * 60 * 24;
    //减去的天数
    var minusDay = week != 0 ? week - 1 : 6;
    //本周 周日
    var monday = new Date(currentDate.getTime() - (minusDay * millisecond));
    var sunday = new Date(monday.getTime() + (6 * millisecond));
    //返回
    return endTime(sunday);
  }
  
  /***
   * 上周的开始
   */
  export function getBeginLastWeek() {
    var currentDate = getCurrentDate();
    var first = currentDate.getDate() - currentDate.getDay() - 6;
    var startDate = new Date(currentDate.setDate(first));
    return startTime(startDate);
  }
  
  /***
   * 上周的结束
   */
  export function getEndLastWeek() {
    var currentDate = getCurrentDate();
    var first = currentDate.getDate() - currentDate.getDay() - 6;
    var last = first + 6;
    var endDate = new Date(currentDate.setDate(last));
    return endTime(endDate);
  }
  
  /***
   * 本月的第一天时间
   */
  export function getBeginMonth() {
    var currentDate = getCurrentDate();
    var currentMonth = currentDate.getMonth();
    //获得当前年份4位年
    var currentYear = currentDate.getFullYear();
    //求出本月第一天
    var firstDay = new Date(currentYear, currentMonth, 1);
  
    return firstDay;
  };
  
  /***
   * 本月的最后一天时间
   */
  export function getEndMonth() {
    //获取当前时间
    var currentDate = getCurrentDate();
    var fullYear = currentDate.getFullYear();
    var month = currentDate.getMonth() + 1; // getMonth 方法返回 0-11，代表1-12月
    var endOfMonth = new Date(fullYear, month, 0);
    return endTime(endOfMonth);
  };
  
  /***
   * 上月的第一天时间
   */
  export function getBeginLastMonth() {
    //获取当前时间
    var currentDate = getCurrentDate();
    //获得当前月份0-11
    var currentMonth = currentDate.getMonth();
    //获得当前年份4位年
    var currentYear = currentDate.getFullYear();
    //获得上一个月的第一天
    var priorMonthFirstDay = getPriorMonthFirstDay(currentYear, currentMonth);
    return priorMonthFirstDay;
  };
  
  /***
   * 上月的最后一天时间
   */
  export function getEndLastMonth() {
    //获取当前时间
    var currentDate = getCurrentDate();
    //获得当前月份0-11
    var currentMonth = currentDate.getMonth();
    //获得当前年份4位年
    var currentYear = currentDate.getFullYear();
  
    //当为12月的时候年份需要加1
    //月份需要更新为0 也就是下一年的第一个月
    if (currentMonth == 11) {
      currentYear++;
      currentMonth = 0; //就为
    } else {
      //否则只是月份增加,以便求的下一月的第一天
      currentMonth++;
    }
  
    //一天的毫秒数
    var millisecond = 1000 * 60 * 60 * 24;
    //求出上月的最后一天
    var lastDay = new Date(getBeginMonth().getTime() - millisecond);
  
    return endTime(lastDay);
  };
  
  /**
   * 返回上一个月的第一天Date类型
   * @param year 年
   * @param month 月
   **/
  export function getPriorMonthFirstDay(year, month) {
    //年份为0代表,是本年的第一月,所以不能减
    if (month == 0) {
      month = 11; //月份为上年的最后月份
      year--; //年份减1
      return new Date(year, month, 1);
    }
    //否则,只减去月份
    month--;
    return new Date(year, month, 1);;
  };
  
  export function year() {

};

// 几天前的时间
export function getDateDiff(time) {
    if (time > 0) {
        var result
        time = parseInt(time);
        var minute = 1000 * 60;
        var hour = minute * 60;
        var day = hour * 24;
        var month = day * 30;
        var now = new Date().getTime();
        var diffValue = now - time;
        if (diffValue < 0) {
            return
        }
        var monthC = diffValue / month;
        var weekC = diffValue / (7 * day);
        var dayC = diffValue / day;
        var hourC = diffValue / hour;
        var minC = diffValue / minute;
        if (monthC >= 1) {
            if (monthC <= 12) {
                result = "" + parseInt(monthC) + "月前";
            } else {
                result = "" + parseInt(monthC / 12) + "年前";
            }
        } else if (weekC >= 1) {
            result = "" + parseInt(weekC) + "周前";
        } else if (dayC >= 1) {
            result = "" + parseInt(dayC) + "天前";
        } else if (hourC >= 1) {
            result = "" + parseInt(hourC) + "小时前";
        } else if (minC >= 1) {
            result = "" + parseInt(minC) + "分钟前";
        } else {
            result = "刚刚";
        }
        return result
    } else {
        return '';
    }



  
}

  // 时间格式化
  export function dateFormat(dateData) {
    if(!dateData) return
    
    let date = new Date(dateData)
    let y = date.getFullYear()
    let m = date.getMonth() + 1
    m = m < 10 ? ('0' + m) : m
    let d = date.getDate()
    d = d < 10 ? ('0' + d) : d
      let h = date.getHours();
      h = h < 10? '0'+ h :h;
      let MM = date.getMinutes();
      MM = MM < 10? '0'+ MM :MM;
      let s = date.getSeconds();
      s = s < 10? '0'+ s :s;
    let time = y + '/' + m + '/' + d+'  '+h +':'+ MM + ':' + s;
    return time

}
// 时间戳
export function timestamp(dateData) {
    if(!dateData) return
    // let date = new Date(dateData)
    // let y = date.getFullYear()
    // let m = date.getMonth() + 1
    // m = m < 10 ? ('0' + m) : m
    // let d = date.getDate()
    // d = d < 10 ? ('0' + d) : d
    //
    // const time = y + '-' + m + '-' + d
    //
    // console.log(time)
    let time = dateFormat(dateData)
    return new Date(time).getTime() / 1000
    // return new Date(time).valueOf() / 1000
    // return Date.parse(time) / 1000date.valueOf()

}