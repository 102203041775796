import request from "@/utils/request";
const prefix = '/publish'


// 获取国家站点
export const amazonRegionCountrySelect = (
    callback
) => {

    return request(`${prefix}/amazonRegionCountrySelect`, { method: 'get'}, callback)
}

// 获取店铺

export const shopSelect = (
    callback
) => {

    return request(`${prefix}/shopSelect`, { method: 'get'}, callback)
}

// 获取平台模板
export const platformTemplateSelect = (
    params,
    callback
) => {

    return request(`${prefix}/platformTemplateSelect`, { params,method: 'get'}, callback)
}

//产品类别

export const productTypeSelect = (
    params,
    callback
) => {

    return request(`${prefix}/productTypeSelect`, { params,method: 'get'}, callback)
}

//产品类目
export const itemTypeSelect = (
    params,
    callback
) => {

    return request(`${prefix}/itemTypeSelect`, { params,method: 'get'}, callback)
}

//生成表单数据
export const generateFormData = (
    params,
    callback
) => {

    return request(`${prefix}/generateFormData`, { params,method: 'get'}, callback)
}

// 保存模板
export const saveTemplate = (
    data,
    callback
) => {

    return request(`${prefix}/saveTemplate`, { data,method: 'post'}, callback)
}

// 获取模板列表
export const templatePaginate = (
    data,
    callback
) => {

    return request(`${prefix}/templatePaginate`, { data,method: 'post'}, callback)
}
// 获取模板筛选列表
export const templatesScreen = (
    callback
) => {

    return request(`${prefix}/templatesScreen`, { params:{},method: 'get'}, callback)
}

// 删除
export const deleteTemplate = (
    params,
    callback
) => {

    return request(`${prefix}/deleteTemplate`, { params,method: 'delete'}, callback)
}

// template_id 	传参代表编辑 不传参代表复制
export const templateEditorTemplate = (
    data,
    callback
) => {

    return request(`${prefix}/templateEditor`, { data,method: 'put'}, callback)
}

// 获取模板详情
export const templateDetails = (
    params,
    callback
) => {

    return request(`${prefix}/templateDetails`, { params,method: 'get'}, callback)
}
//汇出时判断产品是否同类目
export const exportIsProductSimilar = (
    {
        design_id,
    },
    callback
) => {
    const data = {
        design_id,
    }
    return request(`${prefix}/exportIsProductSimilar`, { params:data,method: 'get'}, callback)
}
//汇出产品
export const exportProducts = (
    {
        design_id,
        user_template_id,
        store_id,
        pricing_template_id
    },
    callback
) => {
    const data = {
        design_id,
        user_template_id,
        store_id,
        pricing_template_id
    }
    return request(`${prefix}/exportProducts`, { params:data,method: 'post'}, callback)
}
//汇出产品——变体信息
export const containsVariantInformation = (
    {
        design_id,
        user_template_id,
    },
    callback
) => {
    const data = {
        design_id,
        user_template_id,
    }
    return request(`${prefix}/containsVariantInformation`, { params:data,method: 'get'}, callback)
}
//导入-生成表单数据

export const importGenerateFormData = (
    {amazon_template},
    callback
) => {
    const formData = new FormData()
    formData.append('amazon_template',amazon_template)
    return request(`${prefix}/importGenerateFormData`, { data:formData,method: 'post'}, callback)
}

export const platformTemplateTypeSelect = (
    callback
) => {
    return request(`${prefix}/platformTemplateTypeSelect`, { method: 'get'}, callback)
}